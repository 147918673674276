import React, { useRef } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { motion } from "framer-motion"
import { Script } from "gatsby"

const HeaderCategory = ({
  titre,
  texte,
  color,
  json,
  svg,
  illustration,
  ...props
}) => {
  const titreArray = titre.split(" ")
  const refLottie = useRef(null)

  const containerVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 1,
      },
    },
  }

  const titleVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.3,
      },
    },
  }

  const textVariants = {
    hidden: {
      x: 0,
    },
    visible: {
      x: "100%",
      transition: {
        duration: 1,
      },
    },
  }

  const imageVariants = {
    hidden: {
      opacity: 0,
      scale: 0.3,
      rotate: 180,
    },
    visible: {
      opacity: 1,
      scale: [0.3, 0.3, 1],
      rotate: 0,
    },
  }

  return (
    <>
      <div className="page-categorie">
        <motion.div
          className="entete-categorie max-w-[1920px] mx-auto"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="entete bg-visuall-bg -ml-5 mb-14 pl-5 py-10">
            <motion.div
              className="grid md:grid-cols-12"
              variants={titleVariants}
            >
              <div className="p-4 md:col-span-4">
                <div
                  className="w-24 mb-5 lottie"
                  data-animation-path={json}
                  data-anim-loop="false"
                  data-name="animation"
                  ref={refLottie}
                />
                <h1
                  className={
                    "relative text-6xl font-bold font-archia pb-5 mb-5 overflow-hidden"
                  }
                  style={{ color: color }}
                >
                  {titreArray[0]} <br />
                  <span className={"font-normal"}>{titreArray[1]}</span>
                  <motion.div
                    className="absolute z-10 inset-0 bg-visuall-bg"
                    variants={textVariants}
                  />
                </h1>
                <p className="relative text-2xl font-display text-visuall-gris-logo overflow-hidden">
                  <div>{texte}</div>
                  <motion.div
                    className="absolute z-10 inset-0 bg-visuall-bg"
                    variants={textVariants}
                  />
                </p>
              </div>
              <motion.div
                className="relative -mb-20 overflow-hidden md:col-span-8 lg:col-start-6"
                variants={imageVariants}
              >
                <GatsbyImage
                  image={illustration}
                  alt={`illustration ${titre}`}
                  className={""}
                />
                <motion.div
                  className="absolute z-10 inset-0 bg-visuall-bg"
                  variants={textVariants}
                />
              </motion.div>
            </motion.div>
          </div>
        </motion.div>
      </div>
      <Script
        src={
          "https://cdnjs.cloudflare.com/ajax/libs/bodymovin/5.10.1/lottie.min.js"
        }
      />
    </>
  )
}

HeaderCategory.propTypes = {}

export default HeaderCategory
