import React from "react"
import loadable from "@loadable/component"

const Images = loadable(() => import("./blocks/Images"))
const Videos = loadable(() => import("./blocks/Videos"))
const SpotlightProjet = loadable(() => import("./blocks/SpotlightProjet"))

const DynamicZone = ({ content, ...props }) => {
  return content.map(block => {
    switch (block.__typename) {
      case "DatoCmsImageSBlock":
        return <Images block={block} key={block.id} />
      case "DatoCmsVideoBlock":
        return <Videos block={block} key={block.id} />
      case "DatoCmsSpotlightProjet":
        return <SpotlightProjet block={block} key={block.id} />
      default:
        return null
    }
  })
}

export default DynamicZone
