import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const SpotlightProjet = ({ title, image, color, slug, ...props }) => {
  return (
    <div className="projet-misenavant my-20 container mx-auto">
      <div className="grid grid-cols-6">
        <div className="col-span-5">
          <div className="relative block">
            <GatsbyImage image={image} alt={`projet ${title}`} />
            <Link
              to={`/projets/${slug}`}
              style={{ borderColor: color, color: color }}
              className="uppercase absolute left-0 bottom-0 h-24 transform -rotate-90 origin-bottom-left translate-x-24 p-4 border-t-[16px] text-xl"
            >
              Voir le projet
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

SpotlightProjet.propTypes = {}

export default SpotlightProjet
